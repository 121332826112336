/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeI from "../../templates/PageTypeI"
import List from "../../components/List"

import TitleImg from "../../images/simcrest-apps-auto-email.png"

import IconManual from "../../images/icon-manual.svg"
import IconLicense from "../../images/icon-license.svg"
import IconPrivacy from "../../images/icon-privacy.svg"

import BlueArrow from "../../images/blue-arrow.svg"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="simcrest-apps summary-info">
      <h5>
        One-click solution to email documents to customers and vendors with
        little effort.
      </h5>
      <List
        flex
        className="concise dark"
        items={[
          {
            text: `Send a collection email to a customer that includes a statement and all supporting documents with one click.`,
          },
          {
            text: `Save hours every day having Auto Email do the hard work of collecting documents, writing email bodies, emailing and storing evidence that emails were sent.`,
          },
          {
            text: `Have invoices, credit memos and other documents emailed when posting/registering.`,
          },
          {
            text: `Documents supported: sales quotes, orders, return orders, shipments, posted invoices, posted credit memos, reminders, finance charge memos, statements, collections, short-paid notices and purchase orders.`,
          },
          {
            text: `Remind customers they have short-paid or have non-paid invoices with a single click.`,
          },
          {
            text: `Also supported in Dynamics 365 Business Central Premium: posted service invoices and posted service credit memos.`,
          },
          {
            text: `Send monthly statement to all customers automatically and without user interaction.`,
          },
          {
            text: `Automatically attach standard documents and files to any email, including specific files that depends on the document that is being emailed.`,
          },
          {
            text: `Send documents (like pricelists, tax documents etc.) on a recurring basis (or one-time) to customers, vendors, and contacts.`,
          },
          {
            text: `Review email log to see when emails were sent and by whom.`,
          },
        ]}
      />
      <div>
        <div>
          <h5>Auto Email is simple to use and saves you a lot of time.</h5>
          <p>
            Auto Email is a solution that makes it effortless to send out emails
            and at the same time release the user immediately even when sending
            1000s of emails. The solution uses an email log to place all the
            emails in and let the App do the rest of the work. Auto Email can
            also be set to automatically email invoices, credit memos, and
            shipments as they are being posted.
            <br />
            <br />
            For accounts receivable, Auto Email can send customer statements and
            collection notices with all open and past due invoices and credit
            memos at a single click of a button.
          </p>
        </div>
        <img src={TitleImg} />
      </div>
      <div>
        <div>
          <h2>Technical Concept</h2>
          <p>
            Auto Email is using an email queue to log the emails in and the Job
            Queue to process the logged documents. Auto Email has an entry log
            that is populated every time a document is submitted to be emailed.
            The Job Queue will check the log every X minutes and if there are
            entries that have not been emailed the App will automatically start
            emailing the documents until all have been emailed. The log will be
            updated once the document has been emailed with a status to ensure
            that the document is not emailed again. This means that there is
            nothing emailed from the client and the user is instantly released
            once the documents have been submitted.
          </p>
        </div>
        <div>
          <p>
            This means that there is nothing emailed from the client and the
            user is instantly released once the documents have been submitted.
            <br />
            <br />
            Auto Email can be acquired as an App in the Extension Market Place
            inside Dynamics 365 Business Central or as an On Premise App. Just
            click Free trial to install and try out the solution.
          </p>
          <div className="blue-arrow-link">
            <img className="blue-arrow" src={BlueArrow} alt="Arrow" />
            <a
              href="https://appsource.microsoft.com/en-us/product/dynamics-365-business-central/PUBID.sim_crest%7CAID.autoemail%7CPAPPID.1c9f9809-0e2b-40c9-905b-51916c86f167"
              target="_blank"
            >
              See the App on Dynamics 365 Business Central
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeI
      title="Auto Email"
      description="Auto Email is a solution that makes it effortless to send out emails and at the same time release the user immediately even when sending 1000s of emails."
      linkSubPageGroupID="SimCrestAppsGroup"
      subTitle="Auto Email for Microsoft Dynamics 365 Business Central"
      summaryInfo={summaryInfo}
      videos={[
        {
          title: "Introduction to Auto Email",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/prH10fh4CJk"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
        {
          title: "4 minute overview of Auto Email",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/MV-nq8-iftE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
        {
          title: "Features and Setup of Auto Email",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/yrf_xGM3GM0"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
      ]}
      documents={[
        {
          title: "Manual",
          link: "/docs/manual-auto-email.pdf",
          icon: IconManual,
        },
        {
          title: "License",
          link: "/docs/license-auto-email.pdf",
          icon: IconLicense,
        },
        {
          title: "Privacy",
          link: "/docs/privacy-auto-email.pdf",
          icon: IconPrivacy,
        },
      ]}
    />
  )
}
